@import '../../../../scss/_theme-bootstrap';

.gnav-signup {
  &--no-trigger {
    position: relative;
  }
  &-trigger-label {
    color: $color-navy;
    cursor: pointer;
    align-items: center;
    letter-spacing: 1px;
    line-height: 1.4;
    text-decoration: none;
    border: none;
    background: none;
    padding: 0;
    &:hover {
      color: $color-navy;
      text-decoration: none;
    }
  }
  &-overlay {
    position: static;
    [role='dialog'] {
      box-sizing: border-box;
      border: 1px solid $color-navy;
      background-color: $color-white;
      z-index: $nav-overlay-z-index;
      @media #{$xlarge-up} {
        min-height: auto;
        position: absolute;
        min-width: 450px;
        #{$rdirection}: -22px;
        top: $header-item-height - 1px;
        padding: 30px 30px 0;
      }
      .is-sticky & {
        @media #{$xlarge-up} {
          top: $header-item-height - 5px;
        }
      }
      .gnav-signup--no-trigger & {
        @media #{$xlarge-up} {
          top: $header-item-height + 24px;
          #{$rdirection}: 0;
        }
        .is-sticky & {
          @media #{$xlarge-up} {
            top: $header-item-height + 20px;
          }
        }
      }
    }
    &__close {
      position: absolute;
      border: none;
      background: none;
      padding: 5px;
      #{$rdirection}: 5px;
      top: 21px;
      @media #{$xlarge-up} {
        top: 5px;
      }
    }
    &__icon--close {
      height: 20px;
      width: 20px;
    }
  }
}
